// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery-ui/ui/widget")
require("jquery-ui/ui/widgets/sortable")
require("chartkick")
require("chart.js")
require("trix")
require("packs/input_spinner.js")
require("../src/javascripts/chart/chartjs-plugin-labels.min.js")
require('datatables.net')
require('datatables.net-bs4')
require('datatables.net-responsive-bs4')
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.jQuery = $;
window.$ = $;

require("bootstrap/dist/js/bootstrap")
var moment = require('moment')

global.moment = moment;
window.moment = moment;

require.context('../images', true);
require.context('../fonts', true);

import '../src/javascripts/nicescroll/nicescroll.min.js'
import '../src/javascripts/app/app.js'
import '../src/javascripts/manehub.js'
import '../src/javascripts/app/components/scrollable/scrollable.js'
import '../src/javascripts/calendar/fullcalendar.min.js'
import '../src/javascripts/chart/chart.min.js'
import '../src/javascripts/progress/circle-progress.min.js'
//import '../src/javascripts/chart/chartjs-plugin-style.min.js'
import '../src/javascripts/bootstrap_wizard/jquery.validate.min.js'
import "cocoon";
import "../src/javascripts/datepicker/daterangepicker.js";



const dataTables = [];

$( document ).on('turbolinks:load', function() {
  if (dataTables.length === 0 && $('.data-table').length !== 0) {
    $('.data-table').each((_, element) => {
      dataTables.push($(element).DataTable({
        responsive: true
      }));
    });
  }
});

$( document ).on('turbolinks:before-cache', function() {
  while (dataTables.length !== 0) {
    dataTables.pop().destroy();
  }
});


$( document ).on('turbolinks:load', function() {
  $('[data-toggle="tooltip"]').tooltip()
  let $loader = $('.ajax-loader');

  // hide spinner
  $loader.hide();
  $.spinner = true;

  $(document).on("ajaxStart", function() {
    if($.spinner)
      $loader.show();
  });

  $(document).on("ajaxStop", function() {
    $loader.hide();
  });

  $(".date-picker").daterangepicker({
    singleDatePicker: true,
    timePicker: false,
    locale: {
      format: 'DD/MM/YYYY'
    }
  });
});

const datePickers = [];

$( document ).on('turbolinks:load', function() {
  if (datePickers.length === 0 && $('.date-picker').length !== 0) {
    $('.date-picker').each((_, element) => {
      datePickers.push($(element).daterangepicker({
        singleDatePicker: true,
        timePicker: false,
        locale: {
          format: 'DD/MM/YYYY'
        }
      }));
    });
  }
});

$( document ).on('turbolinks:before-cache', function() {
  while (datePickers.length !== 0) {
    datePickers.pop().destroy();
  }
});

